<template>
  <div>
    <div style="float:left; margin-right: 10px; margin-top: -30px; margin-bottom:-30px">
      <v-card>
        <a
          :href="vehicle.vdp_url"
          target="_blank"
        >
          <v-img
            v-if="photo"
            :src="vehicle.image_url"
            max-width="105"
            min-width="105"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular />
              </v-row>
            </template>
          </v-img>
        </a>
      </v-card>
    </div>
    <div style="float:left;font-weight: 700">
      <template v-if="linkphoto">
        <router-link
          :to="{ name: 'Vehicle Details', params: { vehicle_id: vehicle.id } }"
        >
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
          {{ vehicle.trim }}
        </router-link>
      </template>
      <template v-else>
        <a
          :href="vehicle.vdp_url"
          target="_blank"
        >
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
          {{ vehicle.trim }}
        </a>
      </template>
    </div>
    <br>
    <div class="caps">
      {{ vehicle.exterior_color }}
      <template v-if="vehicle.interior_color">
        / {{ vehicle.interior_color }}
      </template>
      <br>
    </div>
    {{ vehicle.drive_train }}
    <template v-if="vehicle.drive_train && vehicle.fuel_type">
      /
    </template>
    <template v-if="vehicle.fuel_type">
      {{ vehicle.fuel_type }}
    </template>
    <br>
    <template v-if="vehicle.stock_number">
      # {{ vehicle.stock_number }}
    </template>
  </div>
</template>

<script>
  export default {
    name: 'VehicleTopLine',
    props: {
      vehicle: {
        type: Object,
        default: () => ({}),
      },
      photo: {
        type: Boolean,
        default: () => (true),
      },
      linkphoto: {
        type: Boolean,
        default: () => (true),
      },
    },
    data () {
      return {}
    },
  }
</script>
<style scoped>
.caps {
  text-transform: uppercase;
}
.vehicle_photo {
    border: 8px solid #ccc;
    border-bottom: 10px solid #ccc;
}
</style>
