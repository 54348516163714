var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('base-v-component',{attrs:{"heading":_vm.filterMessage()}}),(_vm.error)?[_c('v-banner',{attrs:{"single-line":"","elevation":"10"}},[_c('v-icon',{attrs:{"slot":"icon","color":"warning","size":"36"},slot:"icon"},[_vm._v(" mdi-wifi-strength-alert-outline ")]),_c('div',[_vm._v(" There was a problem communicating to the server! "),_c('div',{staticClass:"code"},[_c('strong',[_vm._v(" "+_vm._s(_vm.error)+" ")])])])],1)]:_vm._e(),(this.$route.query.filter)?[_c('span',{staticClass:"font-weight-light subtitle-1"},[_c('v-btn',{attrs:{"elevation":"1","color":"blue darken-1","dark":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-keyboard-backspace ")]),_vm._v(" Back ")],1)],1)]:_vm._e(),(_vm.items.length > 0)?[_c('v-row',[_c('v-col',[_c('h2',[_vm._v(" "+_vm._s(_vm.items.length)+" Vehicles ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-col',{staticClass:"text-right"},[_c('download-excel',{attrs:{"data":_vm.parsedItems,"type":"csv","name":_vm.fileName}},[_c('v-icon',_vm._g({attrs:{"large":"","color":"green darken-2"}},on),[_vm._v(" mdi-microsoft-excel ")])],1)],1)]}}],null,false,3356979518)},[_c('span',[_vm._v("Download")])])],1)]:_vm._e(),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":50,"sort-by":['make']},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('vehicle-top-line',{attrs:{"vehicle":item}})]}},{key:"item.price",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.on_website?",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"slot":"icon","color":_vm.getCheckBoxIcon(value).color,"size":"36"},slot:"icon"},[_vm._v(" "+_vm._s(_vm.getCheckBoxIcon(value).icon)+" ")])]}},{key:"item.in_dms?",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"slot":"icon","color":_vm.getCheckBoxIcon(value).color,"size":"36"},slot:"icon"},[_vm._v(" "+_vm._s(_vm.getCheckBoxIcon(value).icon)+" ")])]}},{key:"item.mileage",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.number_leads",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getLeadColor(value, _vm.alertSettings),"to":{ name: 'Vehicle Leads', params: { vehicle_id: item.id } },"dark":""}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.cached_age",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":_vm.getAgeColor(value, _vm.alertSettings),"dark":""}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.days_since_last_price_change",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDaysSincePriceChangeColor(value, _vm.alertSettings),"to":{ name: 'Vehicle Price Changes', params: { vehicle_id: item.id } },"dark":""}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.vehicle_photo_count",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":_vm.getPhotoColor(_vm.formatNumber(value), _vm.alertSettings),"dark":""}},[_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Loading "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }