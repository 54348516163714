export const countColorsMixin = {
  methods: {
    getLeadColor: function (leads, alertSettings) {
      if (leads > alertSettings.lead_color_green) return 'green lighten-1'
      else if (leads > alertSettings.lead_color_orange) return 'orange'
      else return 'red darken-1'
    },
    getAgeColor: function (age, alertSettings) {
      if (age > alertSettings.age_color_red) return 'red darken-1'
      else if (age > alertSettings.age_color_orange) return 'orange'
      else if (age > alertSettings.age_color_yellow) return 'yellow accent-4'
      else return 'green lighten-1'
    },
    getPhotoColor: function (photoCount, alertSettings) {
      if (photoCount > alertSettings.photo_color_green) return 'green'
      else if (photoCount > alertSettings.photo_color_orange) return 'orange'
      else return 'red'
    },
    getDaysSincePriceChangeColor: function (days, alertSettings) {
      if (days > alertSettings.days_since_price_change_red) return 'red darken-1'
      else if (days > alertSettings.days_since_price_change_orange) return 'orange'
      else if (days > alertSettings.days_since_price_change_yellow) return 'yellow accent-4'
      else return 'green lighten-1'
    },
    getCheckBoxIcon: function (value) {
      if (value) {
        return { icon: 'mdi-check', color: 'green lighten-1' }
      } else { return { icon: 'mdi-alert', color: 'orange' } }
    },
    getPriceColor: function (value) {
      if (value === null || value === '' || value === 0 || value === '0' || value < 0) {
        return 'red darken-1'
      } else { return 'green lighten-1' }
    },
    getPercentChangeColor: function (value) {
      if (value === null || value === '' || value === 0 || value === '0' || value < 0) {
        return 'red darken-1'
      } else { return 'green lighten-1' }
    },
    getPercentChangeIcon (value) {
      if (value === null || value === '' || value === 0 || value === '0' || (typeof value === 'undefined')) {
        return ''
      }
      if (value < 0) {
        return 'mdi-arrow-down'
      } else { return 'mdi-arrow-up' }
    },
  },
}
