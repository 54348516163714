import axios from 'axios'

export default {
  getVehicles (filter = null) {
    var url = '/vehicles'
    if (filter) {
      url = url + '?filter=' + filter
    }
    return axios.get(url)
  },
  getVdpViews (vehicleId) {
    return axios.get('/vdp_views?vehicle_id=' + vehicleId)
  },
  getLeads (vehicleId) {
    return axios.get('/vehicles/' + vehicleId + '/leads')
  },
  getPriceChanges (vehicleId) {
    return axios.get('/vehicles/' + vehicleId + '/price_changes')
  },
  getVehicleGroups () {
    return axios.get('/vehicle_buckets')
  },
  getVehicle (vehicleId) {
    return axios.get('/vehicles/' + vehicleId)
  },
  getSoldVehicles (data) {
    return axios.get('/sold_vehicles?start_date=' + data.startDate + '&end_date=' + data.endDate + '&unattributed=' + data.unattributed)
  },
  updateSoldVehicle (data) {
    return axios.patch('/sold_vehicles/' + data.id + '?vendor_name=' + data.vendor_name + '&sale_type=' + data.sale_type)
  },
}
