<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      :heading="filterMessage()"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <template v-if="this.$route.query.filter">
      <span
        class="font-weight-light subtitle-1"
      >
        <v-btn
          elevation="1"
          color="blue darken-1"
          dark
          @click="goBack"
        >
          <v-icon
            color="white"
          >
            mdi-keyboard-backspace
          </v-icon>
          Back
        </v-btn>
      </span>
    </template>
    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Vehicles
          </h2>
        </v-col>

        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-col class="text-right">
              <download-excel
                :data="parsedItems"
                type="csv"
                :name="fileName"
              >
                <v-icon
                  large
                  color="green darken-2"
                  v-on="on"
                >
                  mdi-microsoft-excel
                </v-icon>
              </download-excel>
            </v-col>
          </template>
          <span>Download</span>
        </v-tooltip>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['make']"
          >
            <template #item.id="{value, item}">
              <vehicle-top-line :vehicle="item" />
            </template>
            <template #item.price="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.on_website?="{value}">
              <v-icon
                slot="icon"
                :color="getCheckBoxIcon(value).color"
                size="36"
              >
                {{ getCheckBoxIcon(value).icon }}
              </v-icon>
            </template>
            <template #item.in_dms?="{value}">
              <v-icon
                slot="icon"
                :color="getCheckBoxIcon(value).color"
                size="36"
              >
                {{ getCheckBoxIcon(value).icon }}
              </v-icon>
            </template>
            <template #item.mileage="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #item.number_leads="{value, item}">
              <v-chip
                :color="getLeadColor(value, alertSettings)"
                :to="{ name: 'Vehicle Leads', params: { vehicle_id: item.id } }"
                dark
              >
                {{ value }}
              </v-chip>
            </template>
            <template #item.cached_age="{value}">
              <v-chip
                :color="getAgeColor(value, alertSettings)"
                dark
              >
                {{ value }}
              </v-chip>
            </template>
            <template #item.days_since_last_price_change="{value, item}">
              <v-chip
                :color="getDaysSincePriceChangeColor(value, alertSettings)"
                :to="{ name: 'Vehicle Price Changes', params: { vehicle_id: item.id } }"
                dark
              >
                {{ value }}
              </v-chip>
            </template>
            <template #item.vehicle_photo_count="{value}">
              <v-chip
                :color="getPhotoColor(formatNumber(value), alertSettings)"
                dark
              >
                {{ formatNumber(value) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import VehicleService from '@/services/VehicleService.js'
  import VehicleTopLine from '@/components/VehicleTopLine.vue'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { countColorsMixin } from '@/mixins/countColorsMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'

  export default {
    name: 'VehicleList',
    components: {
      VehicleTopLine,
    },
    mixins: [formatterMixin, countColorsMixin, navigationMixin],
    data () {
      return {
        items: [],
        settings: {},
        search: '',
        loading: true,
        error: '',
        expanded: [],
        singleExpand: false,
        headers: [
          { text: 'Vehicle', value: 'id' },
          { text: 'Age', value: 'cached_age', align: 'center' },
          { text: 'On Website', value: 'on_website?', align: 'center' },
          { text: 'In DMS', value: 'in_dms?', align: 'center' },
          { text: 'Mileage', value: 'mileage', align: 'center' },
          { text: 'Price', value: 'price', align: 'center' },
          { text: 'Year', value: 'year', align: ' d-none' },
          { text: 'Make', value: 'make', align: ' d-none' },
          { text: 'Model', value: 'model', align: ' d-none' },
          { text: 'Trim', value: 'trim', align: ' d-none' },
          { text: 'Exterior Color', value: 'exterior_color', align: ' d-none' },
          { text: 'Interior Color', value: 'interior_color', align: ' d-none' },
          { text: 'Fuel Type', value: 'fuel_type', align: ' d-none' },
          { text: 'Drive Train', value: 'drive_train', align: ' d-none' },
          { text: 'Stock', value: 'stock_number', align: ' d-none' },
          { text: 'Leads', value: 'number_leads', align: 'center' },
          {
            text: 'Days Since Price Change',
            value: 'days_since_last_price_change',
            align: 'center',
          },
          { text: 'Photos', value: 'vehicle_photo_count', align: 'center' },
          { text: '', value: 'data-table-expand' },
        ],
        filter: null,
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_vehicles.xls`
      },
      ...mapGetters(['accountId', 'alertSettings', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadVehicles()
      },
    },
    created () {
      this.loadVehicles()
    },
    methods: {
      loadVehicles: function () {
        this.filter = this.$route.query.filter
        this.items = []
        this.loading = true
        VehicleService.getVehicles(this.filter)
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      filterMessage () {
        var message = 'Vehicles'
        switch (this.$route.query.filter) {
          case 'age_0':
            message = 'Vehicles aged 0 - 30 days'
            break
          case 'age_31':
            message = 'Vehicles aged 31 - 60 days'
            break
          case 'age_61':
            message = 'Vehicles aged 61 - 90 days'
            break
          case 'age_90':
            message = 'Vehicles aged over 90 days'
            break
          case 'not_on_website':
            message = 'Vehicles missing from website'
            break
          case 'needs_price_updates':
            message = 'Vehicles needing price updates'
            break
          case 'missing_photos':
            message = 'Vehicles with incomplete photos'
            break
          case 'no_activity':
            message = 'Vehicles with no activity'
            break
        }
        return message
      },
    },
  }
</script>
